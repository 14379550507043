import './assets/css/main.css'
import './assets/css/base.css'
import './assets/css/style.css'

import { createApp } from 'vue'
import App from './App.vue'
import routes from '~pages'
import { createRouter, createWebHistory } from 'vue-router'
import V3ScrollLock from 'v3-scroll-lock';

import * as Sentry from "@sentry/vue";

const app = createApp(App)

app.use(V3ScrollLock, {})

const router = createRouter({
  history: createWebHistory(),
  routes,
});

Sentry.init({
  app,
  dsn: "https://44823af01dedece344d11bdeffd5633d@o4508414865047552.ingest.de.sentry.io/4508414866620496",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing

  
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
app.use(V3ScrollLock, {});
app.mount('#app')
